.az-proppanel-label-info {
  color: blue
}

.animateTrafficFlowLeft2Right {
  stroke-dasharray: 8;
  animation: dash 0.5s linear;
  animation-iteration-count: infinite;
  animation-name: flowLeftToRight;
}
@keyframes flowLeftToRight {
  to {
    stroke-dashoffset: 15;
  }
}

.animateTrafficFlowRight2Left {
  stroke-dasharray: 8;
  animation: dash 0.5s linear;
  animation-iteration-count: infinite;
  animation-name: flowRightToLeft;
}
@keyframes flowRightToLeft {
  to {
    stroke-dashoffset: -15;
  }
}

html, body, #root, .container, #root > div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

inline {
  display: inline;
}

.tile-panel {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  width: 100%;
}
.tile-item {
  flex: 1 1 18%;
  margin: 8px;
}
.azure-rsc-icon {
  width: 30px;
  height: 30px;
}
.tile-text {
  font-size: small;
  font-family: 'Segoe UI';
  display: block;
  font-size: smaller;
}

.collapse-header {
  display: -ms-flexbox;
  border-bottom: solid;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  line-height: 22px;
  padding: 2px 2px;
  color: #666;
  cursor: pointer;
  margin: 0px;
}



/*workbench layout: header, diagramEditor and resourcepalette*/
.workbenchgrid-container{
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.sidebar {
  position: relative;
  margin-top: 1px;
  margin-bottom: 8px;
  width: 275px;
  overflow-y: auto;
  overflow-x: hidden;
}

.diagramEditor{
  /* !important */
  /* overflow:hidden; */
  /* position:relative; */
  width: 100%;
  height:100%;
  border: solid 1px black;
  background-color: #fff;
}

.stylePropPanel {
  background-color: #FFF8E5;
  text-align: center;
  padding: 10px;
  overflow: auto;
}
.propPanelDrawer {
  background-color: #d1d3d4;
  padding: 10px;
  width: 100%;
}
.propPanelGrid {
  background-color: white;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}
.propPanelTabContent {
  background-color: white;
  width: 100%;
  height: 100%;
  padding-top: 0px;
}

.formLayout {
  display: block;
  padding:4px 2px;
  width:100%;
  margin:2px 0 20px 10px;
}

/*blueprintjs button style override*/
.buttonStretch {
  width: 100%;
  flex-grow: 1;
  margin-top: 3px;
}

/* centers circular progress */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  background-color: white;
}