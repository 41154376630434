.login-overlay {
  background-color: #fff;
  border: 1px solid #000;
  text-align:center;
  height: 200px;/*height needs to be set*/
  width: 450px;
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  }
.workspace-overlay-box {
    background-color: #fff;
    border: 1px solid #000;
    text-align:center;
    height: 700px;/*height needs to be set*/
    width: 1100px;
    margin: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    }
  .provision-overlay-box {
    background-color: #fff;
    border: 1px solid #000;

    height: 420px;/*height needs to be set*/
    width: 460px;
    margin: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .myspace-overrideexistingrow-overlay {
    background-color: #fff;
    border: 1px solid #000;
    text-align:center;
    height: 150px;/*height needs to be set*/
    width: 320px;
    margin: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .saveto-workspace-overlay-box {
      background-color: #fff;
      border: 1px solid #000;
      text-align:center;
      height: 280px;/*height needs to be set*/
      width: 430px;
      margin: auto;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      }
.sharedDiagram-MySpace-overlay-box {
    background-color: #fff;
    border: 1px solid #000;
    text-align:left;
    height: 180px;/*height needs to be set*/
    width: 460px;
    margin: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.workspace-loginoptionprompt-overlay-box {
  background-color: #fff;
  border: 1px solid #000;
  text-align:center;
  height: 110px;/*height needs to be set*/
  width: 360px;
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.workspace-deletediagramdialog-overlay-box {
  background-color: #fff;
  border: 1px solid #000;
  text-align:center;
  height: 140px;/*height needs to be set*/
  width: 350px;
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
    .workspace-overlay-about {
      background-color: #fff;
      border: 1px solid #000;
      text-align:center;
      height: 210px;/*height needs to be set*/
      width: 600px;
      margin: auto;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .overlay-preview {
      background-color: #fff;
      height: 700px;/*height needs to be set*/
      width: 800px;
      margin: auto;
      position: fixed;
      padding: 5px;
      overflow: auto;
    }